.home_image {
  width: 100%;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_image {
    margin-bottom: -220px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home_image {
    margin-bottom: -220px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (midin-wth: 768px) {
  .home_image {
    margin-bottom: -220px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home_image {
    margin-bottom: -220px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home_image {
    margin-bottom: -260px;
  }
}

@media only screen and (min-width: 1300px) {
  .home_image {
    margin-bottom: -290px;
  }
}

@media only screen and (min-width: 1400px) {
  .home_image {
    margin-bottom: -330px;
  }
}
.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home_row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}
