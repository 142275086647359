.sign_up {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 50px;
}
.sign_up_logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}

.sign_up_container {
  color: #8a152a;
  background-color: #f8f7ee;
  text-align: center;
  align-items: center;
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}
.sign_up_form {
  min-width: 300px;
}
.sign_up_container > h1 {
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
}
.sign_up_container > form > h5 {
  text-align: left;
  margin: 5px 0px 10px 20px;
}

.sign_up_container > form > input {
  outline: none;
  text-align: center;
  border-radius: 25px;
  padding: 0px 10px;
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 85%;
  border: 1px solid lightgray;
  letter-spacing: 1px;
  font-weight: 100;
  font-family: "Amazon Ember";
}
.sign_up_container > form > input:focus {
  box-shadow: 0 0px 4px rgba(138, 21, 42, 0.2);
}
.sign_up_page_password {
  font-size: large;
}

.sign_up_container > p {
  margin-top: 15px;
  font-size: 12px;
}

.errorMessage {
  margin-top: 0px;
  font-size: 12px;
  color: red;
}
.sign_up_container > hr {
  color: rgb(255, 255, 255);
  width: 98%;
  margin-bottom: 0px;
  margin-top: 10px;
}
.sign_up_register_button {
  border-radius: 25px;
  background-color: #8a152a;
  width: 80%;
  height: 30px;
  border: none;
  margin-top: 10px;
  color: #f8f7ee;
}
.sign_up_sign_in_button {
  border-radius: 25px;
  width: 150px;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: #8a152a;
  border-color: darkgray;
  background-color: #cac474;
}
.sign_up_register_button:hover,
.sign_up_register_button:active {
  box-shadow: 0 1px 4px rgba(138, 21, 42, 0.3);
  background-color: #ab3147;
}
.sign_up_sign_in_button:hover,
.sign_up_sign_in_button:active {
  background-color: #dcd794;
}

.showOtpBackButton:hover,
.showOtpBackButton:active {
  box-shadow: 0 1px 4px rgba(138, 21, 42, 0.3);
  background-color: #dcd794;
}

.showOtpBackButton {
  color: #8a152a;
  background-color: #cac474;
  border-radius: 25px;
  padding: 4px 10px;
  border: 1px solid;
  border-color: darkgray;
}

.react-tel-input .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  border-radius: 30px 0 0 30px !important;
}
.signUp-spinner {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 4px rgba(138, 21, 42, 0.3);
  z-index: 1000;
}
.signUp-spinner img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 45px;
}

.otpInputContainer {
  margin-bottom: 10px;
}

.opt-container input {
  margin: 0 5px !important;
}
