.add_product_form_container {
  text-align: center;
  margin: 0;
  font-family: "Amazon Ember";
  padding: 0 0 50px 0;
}

.add_product_form_container > h1 {
  margin: 20px 0;
}

.add_product_form {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  text-align: left;
  width: 500px;
}

.add_product_inputfields {
  margin-top: 20px;
}

.add_product_rating_radio_buttons > input {
  margin: 5px 5px 5px 10px;
}

.add_product_description_textarea {
  line-height: 1.5;
  overflow: auto;
  resize: vertical;
}

.add_product_categories > select {
  font-size: inherit;
  font-family: inherit;
  width: 100%;
  padding: 8px;
  padding-left: 15px;
  margin: 5px 0;
  border: 1px solid #ccc;
  background-color: rgb(249, 249, 249);
  border-radius: 4px;
}

.add_product_description_textarea,
.add_product_inputfields > input[type="text"],
.add_product_inputfields > input[type="url"] {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.add_product_manage_categories_button,
.add_product_submit_button,
.add_product_upload_button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}
.add_product_manage_categories_button:hover,
.add_product_upload_button:hover,
.add_product_submit_button:hover {
  background-color: #45a049;
}

.add_product_output_container {
  background-color: #ffecb3;
  padding: 10px;
  white-space: pre-line;
  border-radius: 5px;
  display: none;
  margin: auto;
  margin-top: 20px;
  width: fit-content;
  border-radius: 10px;
  border: 1px solid #ffde7c;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
/* Modal Styles */
.add_product_modal_container {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.add_product_modal_content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Adjust the width as needed */
  max-height: 80%; /* Adjust the maximum height as needed */
  overflow: auto;
  position: relative; /* Add this to the modal content */
  font-size: large;
  text-align: left;
}

.add_product_modal_close_btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.add_product_modal_close_btn:hover,
.add_product_modal_close_btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.add_product_fetched_url_image_container {
  text-align: center;
}

.add_product_fetched_url_image {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  border: 1px solid lightgray;
}
