.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 100vh;
}
.login_logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}
.login_container {
  color: #8a152a;
  background-color: #f8f7ee;
  text-align: center;
  align-items: center;
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}
.Login_form_container {
  min-width: 300px;
}
.login_container > h1 {
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
}
.login_container > form > h5 {
  text-align: left;
  margin: 5px 0px 10px 20px;
}
.login_container > form > input {
  outline: none;
  text-align: center;
  border-radius: 25px;
  padding: 0px 10px;
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 85%;
  border: 1px solid lightgray;
}
.login_container > form > input:focus {
  box-shadow: 0 0px 4px rgba(138, 21, 42, 0.2);
}
.login_page_password {
  font-size: large;
}
.login_container > p {
  margin-top: 15px;
  font-size: 12px;
}
.login_container > hr {
  color: rgb(255, 255, 255);
  width: 98%;
  margin-bottom: 0px;
  margin-top: 10px;
}
.login_sign_in_button {
  border-radius: 25px;
  background-color: #8a152a;
  width: 230px;
  height: 30px;
  border: none;
  margin-top: 10px;
  color: #f8f7ee;
}
.login_register_button {
  border-radius: 25px;
  width: 250px;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: #8a152a;
  border-color: darkgray;
  background-color: #cac474;
}
.login_sign_in_button:hover,
.login_register_button:hover {
  box-shadow: 0 1px 4px rgba(138, 21, 42, 0.3);
}
.Login_error_message {
  color: red;
}
