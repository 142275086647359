@font-face {
  font-family: Amazon Ember;
  font-style: normal;
  font-weight: 400;
  src: local("Amazon Ember"), url("./fonts/Amazon_Ember.woff") format("woff");
}

.portfolio_background_image {
  background-image: url("../src//images/background.png");
}

body {
  background-color: #e8e7d3;
  margin: 0;

  font-family: "Amazon Ember", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
