.checkoutProduct {
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid lightgray;
}

.checkoutProduct_info {
  padding-left: 20px;
  color: #8a152a;
}

.checkoutProduct_info a {
  text-decoration: none;
  color: inherit;
}

.checkoutProduct_info > button {
  background: #cd9042;
  color: #fff;
  border-radius: 15px;
  height: 30px;
  cursor: pointer;
  width: 150px;
  border: none;
  margin-top: 10px;
  transition: background-color 0.3s;
  transition: box-shadow 0.3s;
}

.checkoutProduct_info > button:active,
.checkoutProduct_info > button:hover {
  box-shadow: 0 0 5px rgba(138, 21, 42, 0.4);
  background-color: #d8ab37;
}

.checkoutProduct_image_container {
  width: 180px;
  text-align: center;
}
.checkoutProduct_image {
  border-radius: 15px;
  /* object-fit: contain; */
  max-width: 180px;
  max-height: 180px;
}
.checkoutProduct_rating {
  margin-top: -13px;
  display: flex;
}
.checkoutProduct_title {
  font-size: 17px;
  font-weight: 600;
  margin: 0px !important;
}
.checkoutProduct_price {
  margin-top: 5px;
}
