.header {
  font-family: "Amazon Ember", Arial, sans-serif;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #f8f7ee;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(138, 21, 42, 0.5);
}
.header_search_icon {
  border: 1px solid #cd9042;
  border-left: 0px;
  border-radius: 0px 15px 15px 0px;
  padding: 5px;
  height: 22px !important;
  background-color: #cd9042;
}
.header_search_icon:hover {
  background: #c78835;
  color: white;
}
.header_logo {
  width: 150px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 5px;
}
.header_search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}
.header_search_input {
  border: 1px solid #cd9042;
  border-radius: 15px 0px 0px 15px;
  height: 12px;
  padding: 10px;
  width: 100%;
}
.header_search_input:focus {
  outline: none;
}
.header_nav a {
  text-decoration: none;
}
.header_option_line_one {
  font-size: 10px;
}
.header_option_line_two {
  font-size: 13px;
  font-weight: 700;
}
.header_nav {
  display: flex;
  justify-content: space-evenly;
}
.header_option {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 10px;
  color: #8a152a;
}
.header_option_basket {
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #8a152a;
}
.header_basket_count {
  margin-left: 10px;
  margin-right: 20px;
}
