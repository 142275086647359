/* Header.css */
.header_search_results {
  font-size: small;
  position: absolute;
  top: 100%; /* Position the results below the input */
  left: 10%;
  width: 60%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure the results are above other elements */
  max-height: 250px; /* Limit the height of the results */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  border-radius: 0px 0px 10px 10px;
}
.search_result_item_container {
  display: flex;
  align-items: center;
}
.search_image_div {
  margin-right: 5px;
}
.search_result_image {
  object-fit: contain;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.search_result_info_div {
  margin-left: 10px;
}
.header_search_results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header_search_results li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.header_search_results h3 {
  margin: 0;
}

.header_search_results p {
  margin: 5px 0;
  color: #888;
}
.header_search_results li:hover {
  background-color: whitesmoke;
}
.header_search_results li:active {
  background-color: whitesmoke;
}

.header_search_results ul a {
  color: #8a152a;
  text-decoration: none;
}
