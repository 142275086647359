.checkout_ad {
  width: 100%;
  border-radius: 5px;
}
.checkout {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1450px;
  padding: 20px;
  background-color: white;
  height: max-content;
}
.checkout_title {
  margin: 0px;
  padding: 20px 10px;
  border-bottom: 1px solid lightgray;
}
