.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  padding: 20px;
  background-color: #e8e7d3;
  border: 1px solid #d8d8c5;
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 20px;
}
.subtotal_gift {
  display: flex;
  align-items: center;
}
.subtotal_gift > input {
  margin-right: 5px;
}

.checkout_button:active {
  transform: scale(0.99);
}

.subtotal > button {
  background-color: #8a152a;
  color: #e8e7d3;
  border-radius: 15px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  transition: transform 0.2s; /* Animation */
}
