.payment {
  padding: 20px 80px;
}
.payment_container {
  background-color: white;
}
.payment_container > h2 {
  text-align: center;
  margin: 0;
  padding: 10px;
  font-weight: 400;
  border-bottom: 1px solid lightgray;
}
.payment_section {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 20px;
  margin: 0 20px;
}
.payment_title {
  flex: 0.2;
}
.payment_address,
.payment_items,
.payment_details {
  flex: 0.8;
  padding-left: 30px;
}
.payment_form {
  max-width: 400px;
}
.payment_price > h3 {
  padding-bottom: 20px;
}
.paymentBuyNowButton {
  background-color: #8a152a;
  color: #e8e7d3;
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: large;
  border: 1px solid;
  margin-bottom: 20px;
  transition: transform 0.2s; /* Animation */
}
.paymentBuyNowButton:active {
  transform: scale(0.99);
}
