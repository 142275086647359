.product_details_component {
  margin: 30px auto;
  max-width: 700px;
}

.product_details_container {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 30px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.product_details_container img {
  border-radius: 15px;
  max-width: 400px;
  max-height: 400px;
  height: auto;
  object-fit: contain;
  margin-bottom: 1.5rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.product_details_container img:hover {
  transform: scale(1.6);
  /* transform-origin: top; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.product_details_info {
  color: #8a152a;
  flex: 1;
  text-align: center;
}

.product_details_title {
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  padding: 10px 0;
}

.product_details_price {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.product_details_rating {
  color: #ffcc00;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.product_details_rating span {
  margin: 0 2px;
}

.product_details_container button {
  background: #cd9042;
  color: #fff;
  border-radius: 25px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  transition: background-color 0.3s;
  transition: box-shadow 0.3s;
}
.product_details_container button:active,
.product_details_container button:hover {
  box-shadow: 0 0 5px rgba(138, 21, 42, 0.4);
  background-color: #d8ab37;
}
