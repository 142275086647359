.orders {
  padding: 20px 80px;
}
.orders > h1 {
  margin: 30px 0;
}
.spinner {
  display: flex;
  justify-content: center;
}
