.Toastify__toast-container {
  margin-top: 60px;
}

.product {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  background-color: white;
  z-index: 1;
  border-radius: 10px;
}

.product:hover {
  transition: transform 0.2s; /* Animation */
  transform: scale(1.02);
  box-shadow: 0 0px 8px rgba(138, 21, 42, 0.5);
}

.product_image {
  max-height: 200px;
  max-width: 200px;
  object-fit: contain;
  border-radius: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(138, 21, 42, 0.2);
}

.product_image:hover {
  transition: transform 0.2s; /* Animation */
  transform-origin: bottom;
  transform: scale(1.3);
}
.product_rating {
  margin-top: -13px;
  display: flex;
}
.product > button {
  background: #cd9042;
  color: #fff;
  border-radius: 15px;
  height: 30px;
  width: 100px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: box-shadow 0.3s;
}
.product > button:active,
.product > button:hover {
  box-shadow: 0 0 5px rgba(138, 21, 42, 0.4);
  background-color: #d8ab37;
}

.product_price {
  margin-top: 5px;
}

.product_info {
  color: #8a152a;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
}
.product_info a {
  text-decoration: none;
  color: inherit;
}

.product_title {
  margin: 0px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Adjust this value as needed */
  /* max-width: 100%; */
}
